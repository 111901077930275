<template>
  <b-card title="Informació personal">
    <b-overlay :show="loading" variant="white" :opacity="1">
      <template v-if="!editMode">
        <!-- CLIENT DETAILS -->
        <b-row>
          <b-col cols="12" class="mb-1">
            <h5>Nom</h5>
            <b-card-text>{{ name }}</b-card-text>
          </b-col>
          <b-col cols="12" class="mb-1">
            <h5>Telèfon</h5>
            <b-card-text>{{ phone }}</b-card-text>
          </b-col>
          <b-col cols="12" class="mb-1">
            <h5>Email</h5>
            <b-card-text>{{ email }}</b-card-text>
          </b-col>
          <b-col cols="12" class="mb-1">
            <h5>Tipus d'operació</h5>
            <b-card-text>{{ operation }}</b-card-text>
          </b-col>
          <b-col cols="12" class="mb-1">
            <h5>Interessos</h5>
            <b-card-text>{{ interests }}</b-card-text>
          </b-col>
          <b-col cols="12" class="mb-1">
            <h5>Pobles d'interès</h5>
            <b-card-text>{{ cities }}</b-card-text>
          </b-col>
        </b-row>

        <!-- EDIT BUTTON -->
        <b-row class="mt-2">
          <b-col class="text-right">
            <b-button variant="primary" @click="editMode = true">
              Edita
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template v-else>
        <!-- CLIENT FORM -->
        <real-estate-client-form v-model="editedClient" />

        <!-- BUTTONS -->
        <b-row
          class="my-2 d-flex justify-content-center justify-content-sm-end"
        >
          <b-col cols="6" sm="auto">
            <b-button block variant="light" @click="cancel">
              Cancel·la
            </b-button>
          </b-col>

          <b-col cols="6" sm="auto">
            <b-button
              block
              variant="primary"
              :disabled="!canUpdateClient"
              @click="updateClient"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardText, BOverlay, BButton } from "bootstrap-vue";
import {
  getAccommodationTypeName,
  getRealEstateOperationName,
  notifyError,
} from "@/utils/methods";
import RealEstateClientForm from "@/views/real-estate-clients/new-real-estate-client/components/RealEstateClientForm.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BOverlay,
    BButton,
    RealEstateClientForm,
  },
  data() {
    return {
      editMode: false,
      editedClient: null,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["realEstateClient/loading"];
    },
    client() {
      return this.$store.getters["realEstateClient/client"];
    },
    name() {
      const fullName = [];
      if (this.client?.firstName) fullName.push(this.client.firstName);
      if (this.client?.lastName) fullName.push(this.client.lastName);
      return fullName.length ? fullName.join(" ") : null;
    },
    phone() {
      return this.client?.phone || this.$t("No definit");
    },
    email() {
      return this.client?.email || this.$t("No definit");
    },
    operation() {
      return (
        getRealEstateOperationName(this.client?.operation) ||
        this.$t("No definit")
      );
    },
    interests() {
      if (!this.client?.interests?.length) {
        return this.$t("No definit");
      }
      return this.client.interests
        .map((interest) => {
          return getAccommodationTypeName(interest);
        })
        .join(", ");
    },
    cities() {
      if (!this.client?.cities?.length) {
        return this.$t("No definit");
      }
      return this.client.cities
        .map((city) => {
          return this.getCityName(city);
        })
        .join(", ");
    },
    citiyNames() {
      return this.$store.getters["places/cities"];
    },
    canUpdateClient() {
      return (
        !!this.editedClient?.firstName &&
        !!this.editedClient?.operation &&
        (!!this.editedClient?.email || !!this.editedClient?.phone)
      );
    },
  },
  created() {
    if (!this.loading && !this.client) {
      this.fetchClient();
    }
    if (!this.cities?.length) {
      this.fetchPlaces();
    }
  },
  beforeDestroy() {
    this.$store.dispatch("realEstateClient/reset");
    this.setPageTitle(null);
  },
  methods: {
    fetchClient() {
      const { clientUuid } = this.$route.params;

      if (!clientUuid) {
        notifyError("Error", "The client uuid is not defined");
        return;
      }

      this.$store
        .dispatch("realEstateClient/fetchClient", clientUuid)
        .then(() => {
          this.setPageTitle(this.client);
          this.editedClient = this.client;
        })
        .catch(() => {
          notifyError(
            this.$t("errors.fetchClient.title"),
            this.$t("errors.fetchClient.description")
          );
        });
    },
    fetchPlaces() {
      // TODO: only fetch place of type CITY
      this.$store
        .dispatch("places/fetchPlaces", { pagination: false })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPlaces.title"),
            this.$t("errors.fetchPlaces.description")
          )
        );
    },
    setPageTitle(client) {
      this.$store.dispatch("app/setPageTitle", client?.fullName || null);
      this.$store.dispatch("app/setPageSubtitle", client?.idNumber || null);
    },
    getCityName(citySlug) {
      const foundCity = this.citiyNames.find((city) => city.slug === citySlug);
      return foundCity ? foundCity.name : citySlug;
    },
    cancel() {
      this.editMode = false;
      this.editedClient = this.client;
    },
    updateClient() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("realEstateClient/updateClient", {
          uuid: this.client?.uuid || null,
          firstName: this.editedClient?.firstName,
          lastName: this.editedClient?.lastName || null,
          email: this.editedClient?.email || null,
          phone: this.editedClient?.phone || null,
          operation: this.editedClient?.operation || null,
          interests: this.editedClient?.interests || [],
          cities: this.editedClient?.cities || [],
        })
        .then((client) => {
          this.editedClient = client;
        })
        .catch(() =>
          notifyError(
            "Error",
            "El client no s'ha pogut editar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
          this.editMode = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
